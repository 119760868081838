import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChangepassComponent } from './changepass/changepass.component';
import { PurchaseComponent, AddPurchaseComponent } from './purchase/purchase.component';
import { ExpenseComponent, AddExpenseComponent } from './expense/expense.component';
import { PaymentComponent, AddPaymentComponent } from './payment/payment.component';
import { SaleComponent, AddSaleComponent } from './sale/sale.component';
import { ReportComponent } from './report/report.component';
import { DenominationComponent } from './denomination/denomination.component';
import { UserComponent, AddUserComponent, UpdateUserComponent } from './user/user.component';
import { CategoryComponent, AddCategoryComponent, UpdateCategoryComponent } from './category/category.component';
import { ProductComponent, AddProductComponent, UpdateProductComponent } from './product/product.component';
import { ProductvariantComponent, AddProductvariantComponent, UpdateProductvariantComponent, GalleryProductvariantComponent, PriceProductvariantComponent } from './productvariant/productvariant.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'changepass', component: ChangepassComponent },
  { path: 'home', component: HomeComponent },
  { path: 'dashboard', component: DashboardComponent },
  {
    path: 'category', component: CategoryComponent,
    children: [
      { path: 'add', component: AddCategoryComponent },
      { path: 'update/:code', component: UpdateCategoryComponent }
    ]
  },
  {
    path: 'product', component: ProductComponent,
    children: [
      { path: 'add', component: AddProductComponent },
      { path: 'update/:code', component: UpdateProductComponent }]
  },
  {
    path: 'product/:category', component: ProductComponent,
    children: [
      { path: 'add', component: AddProductComponent },
      { path: 'update/:code', component: UpdateProductComponent }]
  },
  {
    path: 'productvariant', component: ProductvariantComponent,
    children: [
      { path: 'add', component: AddProductvariantComponent },
      { path: 'gallery/:code', component: GalleryProductvariantComponent },
      { path: 'price/:code', component: PriceProductvariantComponent },
      { path: 'update/:code', component: UpdateProductvariantComponent }]
  },
  {
    path: 'productvariant/:product', component: ProductvariantComponent,
    children: [
      { path: 'add', component: AddProductvariantComponent },
      { path: 'gallery/:code', component: GalleryProductvariantComponent },
      { path: 'price/:code', component: PriceProductvariantComponent },
      { path: 'update/:code', component: UpdateProductvariantComponent }]
  },
  {
    path: 'user', component: UserComponent,
    children: [
      { path: 'add', component: AddUserComponent },
      { path: 'update/:code', component: UpdateUserComponent }]
  },
  {
    path: 'purchase', component: PurchaseComponent,
    children: [
      { path: 'add', component: AddPurchaseComponent }]
  },
  {
    path: 'expense', component: ExpenseComponent,
    children: [
      { path: 'add', component: AddExpenseComponent }]
  },
  {
    path: 'payment', component: PaymentComponent,
    children: [
      { path: 'add', component: AddPaymentComponent }]
  },
  {
    path: 'sale', component: SaleComponent,
    children: [
      { path: 'add', component: AddSaleComponent }]
  },
  {
    path: 'report', component: ReportComponent
  },
  {
    path: 'ledger', component: DenominationComponent
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
