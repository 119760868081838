import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ecommerce_Config } from '../Ecommerce_Config';
import { InventoryDetail } from '../dialog/dialog.component';
import * as moment from 'moment';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { ConfirmDialog, UserQuickAddDialog } from '../dialog/dialog.component';

@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.css']
})
export class ExpenseComponent implements OnInit {
  sort_option: string = 'created_at-desc';
  search_qry: string = '';
  loading: boolean = false;
  date: Date = new Date();
  items: any = [];
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private httpClient: HttpClient, public dialog: MatDialog) {
    if (this.route.snapshot.params.category) {
    }
    Ecommerce_Config["page_title"] = 'Expense';
  }
  getExpense() {
    this.loading = true;
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    var query = null;
    if (this.search_qry != '') {
      query = this.search_qry;
    }
    this.httpClient.get(Ecommerce_Config["api_url"] + 'expense/' + query + '/' + this.sort_option + '/' + moment(this.date).format('YYYY-MM-DD'), { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        if (res["code"] == 200) {
          this.items = res["data"];
        } else {
          this._snackBar.open(res["message"], '', {
            duration: 2000,
          });
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  ngOnInit(): void {
    this.getExpense();
  }
  getSum(param, data) {
    var total = 0;
    data.forEach(v => {
      total = total + v[param];
    });
    return total;
  }
}

@Component({
  selector: 'app-add-expense',
  templateUrl: './add.expense.component.html',
  styleUrls: ['./expense.component.css']
})
export class AddExpenseComponent implements OnInit {
  @ViewChild('amt', { static: false }) amt: ElementRef;
  staffCtrl: FormControl = new FormControl();
  staffs: any = [];
  loading_options: boolean = false;
  selected_staff: number = 0;
  loading: boolean = false;
  remark: string = '';
  gst_percent: number = 18;
  amount: number = null;
  enable_gst: boolean = false;
  constructor(public route: ActivatedRoute, private _snackBar: MatSnackBar, private httpClient: HttpClient, private router: Router, public dialog: MatDialog, private cdRef: ChangeDetectorRef) {

  }
  getGSTTotal() {
    var res = 0;
    if (this.enable_gst == true) {
      var total = this.amount;
      res = total * (this.gst_percent / 100);
    }
    return res;
  }
  validateExpense() {
    if (!this.amount || this.amount == 0) {
      this._snackBar.open('Invalid amount', '', {
        duration: 2000,
      });
      return false;
    }
    if (!this.selected_staff || this.selected_staff == 0) {
      this._snackBar.open('Please select staff', '', {
        duration: 2000,
      });
      return false;
    }
    if (!this.remark || this.remark == '') {
      this._snackBar.open('Please provide remark', '', {
        duration: 2000,
      });
      return false;
    }
    this.addExpense();

  }
  addExpense() {
    this.loading = true;
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    var data: any = {};
    data.staff = this.selected_staff;
    data.gst_percent = (this.enable_gst == true ? this.gst_percent : 0);
    data.gst_amount = this.getGSTTotal();
    data.grand_total = (this.amount + this.getGSTTotal());
    data.amount = this.amount;
    data.remark = this.remark;
    this.httpClient.post(Ecommerce_Config["api_url"] + 'expense', data, { headers: header }).subscribe(
      (res) => {
        this.loading = false;
        this._snackBar.open(res["message"], '', {
          duration: 2000,
        });
        if (res["code"] == 200) {
          this.router.navigateByUrl('/expense');
        }
      },
      (error) => {
        this.loading = false;
        this._snackBar.open(error.error.message, '', {
          duration: 2000,
        });
      });
  }
  ngOnInit(): void {
    var token = sessionStorage.getItem("auth_token");
    var header = new HttpHeaders().set('Authorization', 'jwt ' + token);
    this.staffCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.staffs = [];
          this.loading_options = true;
        }),
        switchMap(srch => this.httpClient.get<any>(Ecommerce_Config["api_url"] + 'staff_autocomplete/' + srch, { headers: header })
          .pipe(
            finalize(() => {
              this.loading_options = false
            }),
          )
        )
      )
      .subscribe(res => {
        if (res["code"] == 200) {
          this.staffs = res["data"];
        } else {
          this.staffs = [];
        }
      });
  }
  ngAfterViewInit(): void {
    this.amt.nativeElement.focus();
    this.cdRef.detectChanges();
  }
  selectedOption(val, param, array, idparam, matchval) {
    this[array].forEach(v => {
      if (v[matchval] == val) {
        this.selected_staff = v[idparam];
        return false;
      }
    });
  }
  addUser() {
    var row = { type: 'staff', label: 'Staff' }
    const dialogRef = this.dialog.open(UserQuickAddDialog, {
      minWidth: "40%",
      maxWidth: "40%",
      disableClose: true,
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }
}