import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

//Material components
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

//Compnents
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { LoginComponent } from './login/login.component';
import { UserComponent, AddUserComponent, UpdateUserComponent } from './user/user.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CategoryComponent, AddCategoryComponent, UpdateCategoryComponent } from './category/category.component';
import { ProductComponent, AddProductComponent, UpdateProductComponent } from './product/product.component';
import { ProductvariantComponent, AddProductvariantComponent, UpdateProductvariantComponent, GalleryProductvariantComponent, PriceProductvariantComponent } from './productvariant/productvariant.component';
import { PurchaseComponent, AddPurchaseComponent } from './purchase/purchase.component';
import { PaymentComponent, AddPaymentComponent } from './payment/payment.component';
import { SaleComponent, AddSaleComponent } from './sale/sale.component';
import { DialogComponent, AlertDialog, ConfirmDialog, UserQuickAddDialog, ImagePreviewDialog, BillPreviewDialog, InventoryDetail } from './dialog/dialog.component';
import { DenominationComponent } from './denomination/denomination.component';
import { ReportComponent } from './report/report.component';
import { ChangepassComponent } from './changepass/changepass.component';
import { HomeComponent } from './home/home.component';

//Third party library
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { HotkeyModule } from 'angular2-hotkeys';
import { GoogleChartsModule } from 'angular-google-charts';
import { ExpenseComponent, AddExpenseComponent } from './expense/expense.component';

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    SidenavComponent,
    LoginComponent,
    DashboardComponent,
    CategoryComponent,
    AddCategoryComponent,
    UpdateCategoryComponent,
    ProductComponent,
    AddProductComponent,
    UpdateProductComponent,
    ProductvariantComponent,
    AddProductvariantComponent,
    UpdateProductvariantComponent,
    GalleryProductvariantComponent,
    PriceProductvariantComponent,
    PurchaseComponent,
    AddPurchaseComponent,
    UserComponent,
    AddUserComponent,
    UpdateUserComponent,
    SaleComponent,
    AddSaleComponent,
    DialogComponent,
    AlertDialog,
    ConfirmDialog,
    UserQuickAddDialog,
    ImagePreviewDialog,
    BillPreviewDialog,
    InventoryDetail,
    DenominationComponent,
    ReportComponent,
    ChangepassComponent,
    HomeComponent,
    PaymentComponent,
    AddPaymentComponent,
    ExpenseComponent,
    AddExpenseComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    FlexLayoutModule,
    MaterialFileInputModule,
    RichTextEditorAllModule,
    MatTableModule,
    MatCardModule,
    MatTabsModule,
    GoogleChartsModule,
    HotkeyModule.forRoot()
  ],
  entryComponents: [DialogComponent, AlertDialog, ConfirmDialog, UserQuickAddDialog, ImagePreviewDialog, BillPreviewDialog, InventoryDetail],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
