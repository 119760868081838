<div class="container notoolbar">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayout.xs="column">
        <button mat-raised-button routerLink="/expense">
            Back to Expense
        </button>
    </div>
    <div fxLayout="column" fxLayoutAlign="end stretch" fxLayoutGap="20px" fxLayout.xs="column">
        <div style="width: 70%;" fxLayout="column" fxLayoutAlign="stretch end">
            <mat-form-field appearance="legacy">
                <mat-label>Amount</mat-label>
                <input matInput type="number" step="0.01" placeholder="" [(ngModel)]="amount" #amt />
            </mat-form-field>
            <div class="mat-line" style="text-align: right;"><mat-slide-toggle
                    [(ngModel)]="enable_gst"></mat-slide-toggle>
                <input matInput #gst_percent_ele type="number" *ngIf="enable_gst == true" step="1" placeholder=""
                    [(ngModel)]="gst_percent" style="width: 80px;" />
                <span *ngIf="enable_gst == true">%</span>&nbsp;GST: ₹{{getGSTTotal()|number: '1.2-2'}}
            </div>
            <div class="mat-line" style="text-align: right;">Grand Total: ₹{{(amount + getGSTTotal())|number:
                '1.2-2'}}</div>
            <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="20px">
                <mat-form-field>
                    <mat-label>Staff</mat-label>
                    <input matInput placeholder="" aria-label="State" [matAutocomplete]="auto2"
                        [formControl]="staffCtrl" />
                    <mat-autocomplete #auto2="matAutocomplete"
                        (optionSelected)="selectedOption($event.option.value, 'staff', 'staffs', 'mv_user_id', 'name')">
                        <mat-option *ngIf="loading_options">
                            <mat-progress-spinner [mode]="'indeterminate'" [strokeWidth]="3" [diameter]="25">
                            </mat-progress-spinner>
                        </mat-option>
                        <mat-option *ngFor="let row of staffs" [value]="row.name">
                            {{row.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="legacy">
                    <mat-label>Remark</mat-label>
                    <input matInput type="text" placeholder="" [(ngModel)]="remark" />
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="20px">
                <button mat-raised-button color="accent" (click)="addUser()">Add Staff</button>
                <button mat-raised-button color="primary" (click)="validateExpense()" [disabled]="loading">
                    <span *ngIf="loading == false">Add Expense</span>
                    <mat-progress-spinner *ngIf="loading == true" color="accent" mode="indeterminate" strokeWidth="4"
                        diameter="40" class="auto-margin"></mat-progress-spinner>
                </button>
            </div>
        </div>
    </div>
</div>