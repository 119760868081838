<app-toolbar></app-toolbar>
<div *ngIf="route.children.length === 0">
    <mat-progress-bar *ngIf="loading == true" mode="indeterminate" color="warn"></mat-progress-bar>
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" class="toolbar">
        <div class="action-lbl">
            Actions:
        </div>
        <button mat-raised-button color="accent" routerLink="/expense/add">
            Add Expense
        </button>
        <button mat-raised-button (click)="getExpense()">
            Refresh
        </button>
        <mat-form-field [floatLabel]="'never'">
            <mat-label>Sort by</mat-label>
            <mat-select [(ngModel)]="sort_option" (selectionChange)="getExpense()" [disableOptionCentering]="true">
                <mat-option value="created_at-desc">
                    Date DESC
                </mat-option>
                <mat-option value="created_at-asc">
                    Date ASC
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field [floatLabel]="'never'">
            <input type="text" matInput placeholder="Search" [(ngModel)]="search_qry" (input)="getExpense()" />
        </mat-form-field>
        <mat-form-field [floatLabel]="'never'">
            <input matInput [matDatepicker]="picker" [(ngModel)]="date" (dateChange)="getExpense()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </mat-toolbar-row>
    <div class="container">
        <mat-list>
            <mat-list-item *ngFor="let item of items; let lastItem = last">
                <div mat-line> #{{item.order_id}} {{item.user_name}} spent ₹{{item.grand_total|number:'1.2-2'}} on {{item.created_at|date:'medium'}}</div>
                <div mat-line> {{item.remark}} </div>
                <mat-divider *ngIf="!lastItem"></mat-divider>
            </mat-list-item>
        </mat-list>
        <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="30px">
            <div class="mat-display-2">
                Total: ₹{{getSum('grand_total', items) |number:'1.2-2'}}
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>